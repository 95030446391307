import { Observable } from 'rxjs';
import { ErrorDescription } from '../domain/error-description.type';
import { ArticleMinInfo } from 'src/app/presenter/services/shopnanny.service';
import { ProductInformation } from '../domain/ProductInformation.type';
import { PimInformation } from '@al-ko/types';

export abstract class IPimRepository {
  abstract getProductInformation(
    articleNumber: string,
  ): Observable<PimInformation>;
  abstract getProductInformations(
    articleNumbers: string[],
  ): Observable<{ [articleNumber: string]: ProductInformation }>;
  abstract getSparepartsOfProduct(
    articleNumber: string,
  ): Observable<ProductInformation[]>;
  abstract getErrorDescriptions(): Observable<{
    [errorCode: number]: ErrorDescription;
  }>;
  abstract getTranslations(
    translationPool: string,
  ): Observable<{ [key: string]: string }>;

  abstract searchProduct(articleNumber: string): Observable<ArticleMinInfo[]>;
}
