import {
  Access,
  HistoryData,
  PimInformation,
  Thing,
  ThingType,
} from '@al-ko/types';
import { first, map, Observable, of, Subscription, tap } from 'rxjs';
import { IDeviceInteractor } from '../contracts/idevice.interactor';
import { GetAllDevicesUsecase } from '../../../core/usecases/device-usecases/get-all-devices.usecase';
import { GetDeviceByIdUsecase } from '../../../core/usecases/device-usecases/get-device-by-id.usercase';
import { inject, Injectable, signal, Signal } from '@angular/core';
import { IDeviceConnectionRepository } from '../../../core/repositories/device-connection.repository';
import {
  GetMetaDataOfDeviceUsecase,
  MetaDatas,
} from '../../../core/usecases/get-metadata-of-device.usecase';
import { GetProductInfoUsecase } from '../../../core/usecases/pim-usecases/get-product-info.usecase';
import { GetHistoryDataUsecase } from '../../../core/usecases/history-usecases/get-history-data.usecase';
import { AlkoThing } from 'src/app/core/domain/thing.type';
import { RemoveAccessUsecase } from '@usecases/access-usecases/remove-access.usecase';
import { UpdateDesiredStateUsecase } from '@usecases/device-usecases/update-desired-state.usecase';
import {
  RobolinhoStateWriteable,
  RobolinhoState,
} from 'src/app/core/domain/robolinho-state.type';
import { CreateMetaDataUsecase } from '@usecases/create-metadata.usecase';
import {
  ErrorDescriptionSP,
  ResolveErrorDescriptionUsecase,
} from '@usecases/pim-usecases/resolve-error-description.usecase';
import {
  ErrorDescriptions,
  ResolveErrorDescriptionsUsecase,
} from '@usecases/pim-usecases/resolve-error-descriptions.usecase';
import { environment } from 'src/environments/environment';
import { GetLatestRobolinhoMainboardVersion } from '@usecases/fota-usecases/get-latest-robolinho-mainboard-version.usecase';
import { ThingState } from 'src/app/core/domain/thingstate.type';

@Injectable({ providedIn: 'root' })
export class DeviceInteractor extends IDeviceInteractor {
  private subscriptions: Subscription[] = [];
  private errorDescriptions: ErrorDescriptions;

  private removeAccessUsecase = inject(RemoveAccessUsecase);
  private updateDesiredStateUsecase = inject(UpdateDesiredStateUsecase);
  private createMetaDataUsecase = inject(CreateMetaDataUsecase);
  private resolveErrorDescription = inject(ResolveErrorDescriptionUsecase);
  private resolveErrorDescriptions = inject(ResolveErrorDescriptionsUsecase);
  private getLatestRobolinhoMainboardVersionUsecase = inject(
    GetLatestRobolinhoMainboardVersion,
  );

  constructor(
    private getAllDevicesUsecase: GetAllDevicesUsecase,
    private getDeviceByIdUsecase: GetDeviceByIdUsecase,
    private connectionRepo: IDeviceConnectionRepository,
    private getMetadataUsecase: GetMetaDataOfDeviceUsecase,
    private getProductInfoUsecase: GetProductInfoUsecase,
    private getHistoryDataUsecase: GetHistoryDataUsecase,
    // private subscribeToDeviceUsecase: SubscribeToDeviceUsecase,
  ) {
    super();
  }

  private mergeCurrentStateWithUpdate(
    current: AlkoThing,
    update: AlkoThing,
  ): AlkoThing {
    Object.keys(update).forEach((key) =>
      update[key] === undefined ? delete update[key] : {},
    );
    return { ...current, ...update };
  }

  override getDeviceList(withCached: boolean): Observable<AlkoThing[]> {
    return this.getAllDevicesUsecase.execute(withCached || true).pipe(
      map((x) => {
        for (const thing of x) {
          if (this.devices[thing.id]) {
            this.devices[thing.id].thing.update((current) => {
              return this.mergeCurrentStateWithUpdate(current, thing);
            });
          }
          this.devices[thing.id] = {
            sub: undefined,
            thing: signal(thing),
          };
        }
        return x;
      }),
    );
  }

  override getDevice(thingName: string): Observable<AlkoThing> {
    const o = this.getDeviceByIdUsecase.execute(thingName);
    return o;
  }

  override subscribeToDevice(thingName: string): Observable<ThingState> {
    if (this.devices[thingName].sub) {
      return this.devices[thingName].sub;
    }

    this.devices[thingName].sub = this.connectionRepo.subscribe(thingName);

    this.devices[thingName].sub.subscribe((state) => {
      this.devices[thingName].thing.update((device) => {
        delete state['state'];
        // console.log('MQTT payload after correction:', state);
        device.state = {
          ...device.state,
          ...state,
        };

        return device;
      });
    });

    return this.devices[thingName].sub;
  }

  override unsubscribeToDevice(thingName: string): void {
    if (this.devices[thingName].sub) {
      this.devices[thingName].sub = undefined;
    }
  }

  override getDeviceMetadata(thingName: string): Observable<MetaDatas> {
    return this.getMetadataUsecase.execute(thingName);
  }

  override createDeviceMetadata(
    thingName: string,
    attributeName: string,
    data: any,
  ): Observable<any> {
    return this.createMetaDataUsecase.execute({
      attributeName: attributeName,
      data: data,
      thingName: thingName,
    });
  }

  override getProductInformation(
    articleNumber: string,
  ): Observable<PimInformation> {
    return this.getProductInfoUsecase.execute(articleNumber);
  }

  override getHistoryData(
    thingName: string,
    attributeName: string,
  ): Signal<HistoryData[]> {
    if (!this.historyBook[thingName]) {
      this.historyBook[thingName] = {};
    }

    if (!this.historyBook[thingName][attributeName]) {
      this.historyBook[thingName][attributeName] = signal([]);
    }
    // return of(this.historyBook[thingName][attributeName]);
    return this.historyBook[thingName][attributeName].asReadonly();
  }

  override refreshHistoryData(thingName: string, attributeName: string): void {
    if (
      this.historyBook[thingName] &&
      this.historyBook[thingName][attributeName] &&
      this.historyBook[thingName][attributeName].length > 0
    ) {
      return;
    }

    const sub = this.getHistoryDataUsecase
      .execute({
        attributeName: attributeName,
        thingName: thingName,
      })
      .subscribe((historyData) => {
        this.historyBook[thingName][attributeName].set(historyData);
      });
    this.subscriptions.push(sub);
  }

  override refreshDeviceState(thingName: string): void {
    this.getDeviceByIdUsecase.execute(thingName).subscribe((t) => {
      if (this.devices[thingName] == undefined) {
        this.devices[thingName] = {
          thing: signal(t),
          sub: undefined,
        };
      } else {
        this.devices[thingName].thing.set(t);
      }
    });
  }

  override getDeviceSignal(thingName: string): Signal<AlkoThing> {
    if (this.devices[thingName]) {
      return this.devices[thingName].thing.asReadonly();
    }

    this.devices[thingName] = {
      sub: undefined,
      thing: signal(undefined),
    };

    this.getDeviceByIdUsecase
      .execute(thingName)
      .pipe(first())
      .subscribe((thing) => this.devices[thingName].thing.set(thing));

    // this.devices[thingName] = {
    //   sub: undefined,
    //   thing: signal(demoAlkoThing), // TODO: replace with real usecase
    // };
    return this.devices[thingName].thing.asReadonly();
  }

  override removeAccessToDevice(
    thingName: string,
    accessId: string,
  ): Observable<Access> {
    return this.removeAccessUsecase.execute({
      thingName: thingName,
      accessId: accessId,
    });
  }

  override updateDesiredState(
    thingName: string,
    desiredState: Partial<RobolinhoStateWriteable>,
  ): Observable<Partial<RobolinhoState>> {
    return this.updateDesiredStateUsecase.execute({
      thingId: thingName,
      desiredState: desiredState,
    });
  }

  override getErrorDescription(
    thingType: ThingType,
    errorCode: number,
  ): Observable<ErrorDescriptionSP> {
    console.log('debug errorDescriptions:', this.errorDescriptions);
    if (!this.errorDescriptions) {
      return this.resolveErrorDescriptions.execute().pipe(
        tap((ed) => (this.errorDescriptions = ed)),
        map((ed) => ed[thingType][errorCode]),
      );
    }

    return of(this.errorDescriptions[thingType][errorCode]);
  }

  override getErrorDescriptionsOfType(
    thingType: ThingType,
  ): Observable<{ [errorCode: string]: ErrorDescriptionSP }> {
    if (!this.errorDescriptions) {
      return this.resolveErrorDescriptions.execute().pipe(
        tap((ed) => (this.errorDescriptions = ed)),
        map((ed) => ed[thingType]),
      );
    }

    return of(this.errorDescriptions[thingType]);
  }

  // override getLatestRobolinhoFirmwareVersion(
  //   component: 'mainboard' | 'communication',
  //   articleNumber: string,
  //   localization?: 'SW' | 'NE',
  // ): Observable<string> {
  override getLatestRobolinhoFirmwareVersion(
    articleNumber: string,
    localization?: 'SW' | 'NE',
  ): Observable<string> {
    return this.getLatestRobolinhoMainboardVersionUsecase.execute({
      articleNumber: articleNumber,
      localization: localization,
    });
  }
}

const demoAlkoThing: AlkoThing = {
  id: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
  type: ThingType.robolinho,
  attributes: {
    thingName: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
    thingType: ThingType.robolinho,
    thingModel: 'Robolinho520',
    articleNumber: '127695',
    serialNumber: '3B24-082457',
    firmwareVersion: '2404A-SW',
    hardwareVersion: '1D',
    productionDate: '20230301',
    betatester: 'True',
    serialNumberMain: '3B24-082457',
    hardwareVersionMain: '1D',
    firmwareMain: '2404A-SW',
    firmwareMainLocalization: 'SW',
    articleNumberWifi: '495146',
    serialNumberWifi: '012319DC12BE4A80EE',
    hardwareVersionWifi: '1D',
    firmwareWifi: '1.9.B',
    firmwareWifiDriver: 'V19.6.1',
    fotaMode: 'off',
  },
  accessInformation: {
    accessId: '59cd0aa6-549d-461b-8039-c23f5b15e611',
    thingName: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
    userId: '0008020010',
    idpAccountId: '55261bbe-21e3-4d10-b381-57a8337e4fe8',
    userEmail: 'thomas.echerer@al-ko.com',
    accessAlias: 'Schulungszentrum',
    accessAdmin: true,
    accessCreated: '2024-05-29T07:33:22.853Z',
  },
};

const demoThing: Thing = {
  thingName: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
  thingType: ThingType.robolinho,
  thingAttributes: {
    thingName: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
    thingType: ThingType.robolinho,
    thingModel: 'Robolinho520',
    articleNumber: '127695',
    serialNumber: '3B24-082457',
    firmwareVersion: '2404A-SW',
    hardwareVersion: '1D',
    productionDate: '20230301',
    betatester: 'True',
    serialNumberMain: '3B24-082457',
    hardwareVersionMain: '1D',
    firmwareMain: '2404A-SW',
    firmwareMainLocalization: 'SW',
    articleNumberWifi: '495146',
    serialNumberWifi: '012319DC12BE4A80EE',
    hardwareVersionWifi: '1D',
    firmwareWifi: '1.9.B',
    firmwareWifiDriver: 'V19.6.1',
    fotaMode: 'off',
  },
  accessInformation: {
    accessId: '59cd0aa6-549d-461b-8039-c23f5b15e611',
    thingName: '7f0763440876b365f5e70d2e7a16e0bca6deb5ea',
    userId: '0008020010',
    idpAccountId: '55261bbe-21e3-4d10-b381-57a8337e4fe8',
    userEmail: 'thomas.echerer@al-ko.com',
    accessAlias: 'Schulungszentrum',
    accessAdmin: true,
    accessCreated: '2024-05-29T07:33:22.853Z',
  },
};

function getRandomNumberObservable(): Observable<number> {
  return new Observable<number>((subscriber) => {
    // Emit the first random number immediately
    emitRandomNumber(subscriber);

    // Set up an interval of 2000ms (2 seconds) to emit random numbers
    const intervalId = setInterval(() => {
      emitRandomNumber(subscriber);
    }, 2000);

    // Teardown logic to clear the interval when the Observable is unsubscribed
    return () => clearInterval(intervalId);
  }).pipe(
    map((value) => Math.round(value)), // Ensure the number is rounded to an integer between 0 and 100
  );
}

function emitRandomNumber(subscriber: any): void {
  const randomNumber = Math.random() * 100; // Generate a random number between 0 and 100
  subscriber.next(randomNumber);
}
